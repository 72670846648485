:root {
	--container: max(calc(50% - 600px), 20px);

	--text: #ffff;
	--bg-color-1: #111111;
	--bg-color-2: #2d3038;

	--neon2: #d13030;
	--lightning: #ffff;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: Roboto, sans-serif;
	font-weight: 300;
	font-style: normal;
	font-size: 15px;
	color: var(--text);
	background-color: var(--bg-color-1);
	/* overflow: hidden; */

	-webkit-touch-callout: none; /* Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	&._overlay {
		pointer-events: none;
	}
}

button {
	border: none;
	outline: none;
}

input {
	font-size: 14px;
	color: var(--text);
	box-shadow: none;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 300;
	letter-spacing: 0.5px;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

p {
	color: var(--text);
	margin: 0;
	padding: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.enBold {
	font-family: Benzin-Bold, sans-serif;
}
.enMedium {
	font-family: Benzin-Medium, sans-serif;
}
.hinBold {
	// font-family: hin-Bold, sans-serif;
	font-family: Benzin-Bold, sans-serif;
}
.hinMedium {
	// font-family: hin-Medium, sans-serif;
	font-family: Benzin-Medium, sans-serif;
}
