@import "./fonts.scss";
@import "./img.scss";

.enBold {
	font-family: Benzin-Bold, sans-serif;
}
.enMedium {
	font-family: Benzin-Medium, sans-serif;
}
.hinBold {
	// font-family: hin-Bold, sans-serif;
	font-family: Benzin-Bold, sans-serif;
}
.hinMedium {
	// font-family: hin-Medium;
	font-family: Benzin-Medium, sans-serif;
}

.container {
	max-width: 490px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.checkbox {
	position: relative;
	width: 150px;
	height: 45px;
	display: flex;
	align-items: center;
	border-radius: 50px;
	overflow: hidden;
	font-size: 16px;
	background-image: $switcher;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	margin-bottom: 30px;

	.lang {
		position: absolute;
		top: 50%;
		transform: translateY(-38%);
		color: #424242;
		text-transform: uppercase;
		line-height: 100%;
		z-index: 1;
		transition: linear 0.3s;

		&._active {
			color: var(--text);
		}

		&._left {
			left: 15px;
		}

		&._right {
			right: 20px;
		}
	}

	.switcher {
		position: absolute;
		left: 0;
		top: 0;
		width: 50%;
		height: 100%;
		background-color: #414141;
		border-radius: 50px;
		z-index: 0;
		transition: linear 0.3s;

		&._pos1 {
			transform: translateX(0);
		}
		&._pos2 {
			transform: translateX(100%);
		}
	}
}

.title {
	width: 100%;
	font-size: 14px;
	margin-bottom: 5px;
	text-align: center;
}

.coefficients {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	font-size: 12px;
	margin-bottom: 10px;

	.coef {
		background-color: #577cff;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		border-radius: 8px;
		line-height: 100%;
    transition: linear 0.5s;

		&._1 {
			background-color: rgb(52, 180, 255);
		}
		&._2 {
			background-color: rgb(145, 62, 248);
		}
		&._3 {
			background-color: rgb(192, 23, 180);
		}

		span {
			transform: translateY(16%);
		}
	}
}

.card {
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 20px;

	.cardBody {
		width: 100%;
		height: 365px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #1e1e1e;
		border-radius: 50px;
		backdrop-filter: blur(5px);
		padding: 50px 10px;

		.timerContainer {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 0px;
			.tic {
				width: 30px;
				height: 30px;
				background-image: $tic;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}

			.timer {
				position: relative;
				font-size: 20px;
				margin-top: 5px;
				// margin-bottom: 30px;
				font-family: monospace, sans-serif;
				font-weight: bold;
			}
		}

		.loaderContainer {
			position: relative;
			width: 130px;
			height: 120px;
			margin-bottom: 50px;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 60%;
				height: 60%;
				border-radius: 50%;
				background-color: #f10361;
				opacity: 0.5;
				filter: blur(30px);
			}
		}

		.planeContainer {
			position: relative;
			width: 200px;
      height: 80px;

			.planeAlternative {
				display: none;
				position: absolute;
				transform: translate(-1px, 10px);
				width: 100%;
				height: 80px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;

				&._1 {
					display: block;
					background-image: $plane1;
				}
				&._2 {
					display: block;
					background-image: $plane2;
				}
				&._3 {
					display: block;
					background-image: $plane3;
				}
				&._4 {
					display: block;
					background-image: $plane4;
				}

				&._active {
					display: none;
				}

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 60%;
					height: 60%;
					border-radius: 50%;
					background-color: #f10361;
					opacity: 0.5;
					filter: blur(30px);
				}
			}

			.plane1 {
				display: none;
				position: absolute;
				width: 100%;
				height: 90px;
				background-image: $plane1;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				// opacity: 1;

				&._active {
					display: block;
				}
				// animation: hide1 0.2s infinite linear;

				// @keyframes hide1 {
				// 	0% {
				// 		opacity: 1;
				// 	}

				// 	100% {
				// 		opacity: 0;
				// 	}
				// }
			}
			.plane2 {
				display: block;
				position: absolute;
				transform: translate(-1px, 10px);
				width: 100%;
				height: 80px;
				background-image: $plane2;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;

				&._active {
					display: none;
				}

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 60%;
					height: 60%;
					border-radius: 50%;
					background-color: #f10361;
					opacity: 0.5;
					filter: blur(30px);
				}
			}
			.plane3 {
				position: absolute;
				transform: translate(-1px, 10px);
				width: 100%;
				height: 80px;
        background-image: $planeOrange;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 60%;
					height: 60%;
					border-radius: 50%;
					background-color: #ff3535;
					opacity: 0.5;
					filter: blur(30px);
				}
			}
		}

		.loader {
			animation: manMove 2s infinite linear;
			@keyframes manMove {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.text {
			font-size: 12px;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 30px;
		}

		.coef {
			width: 130px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #2c7e25;
			border-radius: 30px;
			padding: 10px 0px;
			margin-bottom: 30px;

			span {
				font-size: 20px;
			}

			&._2 {
				background-color: #8227ff;
			}
			&._3 {
				background-color: #ff3535;
			}
		}
	}
}

.buttonStart {
	width: 80%;
	height: 70px;
	border-radius: 50px;
	color: var(--text);
	font-size: 26px;
	line-height: 100%;
	background-color: #f10361;
	-webkit-box-shadow: 0px 0px 10px 2px #f10361;
	-moz-box-shadow: 0px 0px 10px 2px #f10361;
	box-shadow: 0px 0px 10px 2px #f10361;
}

.progressWrapper {
	width: 100%;
	max-width: 400px;
	border-radius: 30px;
	overflow: hidden;
}

.progress2 {
	position: relative;
	padding: 6px;
	border-radius: 30px;
	background: #48e13b;
	height: 30px;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
	position: relative;
	overflow: hidden;
	height: 25px;
	border-radius: 30px;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
	transition: 0.4s linear;
	transition-property: width, background-color;
}

.lightning {
	position: absolute;
	top: 0;
	left: 0%;
	transform: translateX(-100%);
	width: 100%;
	height: 100%;
	border-radius: 0px 30px 30px 0px;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
	animation: lightningAnimation 1.5s infinite;
}

@keyframes lightningAnimation {
	0% {
		left: 0%;
		transform: translateX(-100%);
	}
	100% {
		left: 100%;
		transform: translateX(0%);
	}
}

.interest {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: black;
	font-size: 14px;
}

// .progress-moved .progress-bar2 {
// 	background-color: #05c162;
// 	transition: linear 0.1s;
// }
