@import "./img.scss";
@import "./fonts.scss";

.container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 20px 20px;
	background-image: $background;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 400vh;
}

.button {
	width: fit-content;
	height: 70px;
	padding: 0px 30px;
	border-radius: 50px;
	color: var(--text);
	font-size: 26px;
	line-height: 100%;
	background-color: #f10361;
	-webkit-box-shadow: 0px 0px 10px 2px #f10361;
	-moz-box-shadow: 0px 0px 10px 2px #f10361;
	box-shadow: 0px 0px 10px 2px #f10361;
	cursor: pointer;

	&._gray {
		height: 50px;
		font-size: 18px;
		background-color: #252525;
		color: #676767;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}

	&._blue {
		height: 50px;
		font-size: 18px;
		background-color: #0fa9ff;
		color: #fff;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}

	&._small {
		width: 100%;
		max-width: 350px;
		height: 50px;
		font-size: 14px;
		white-space: nowrap;
		padding: 0px 15px;
	}

	&._green {
		background-color: #56ba4d;
	}
	&._orange {
		background-color: #ff5050;
	}
}

.config {
	max-width: 400px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title {
	width: 100%;
	height: 200px;
	background-image: $configTitle;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.label {
	width: 100%;
	display: flex;
	align-items: center;
}

.labelText {
	display: inline-block;
	font-size: 14px;
	margin-right: 5px;
}

.input {
	width: 100%;
	color: white;
	height: 40px;
	border-radius: 30px;
	border: 1px solid #d7d7d7;
	outline: none;
	padding: 0 20px;
	background-color: rgba(27, 26, 26, 0.397);
}
